import '../scss/index.scss';
import '@smithsonian/js';
import $ from 'jquery';
import '../scss/photocontest.scss';

import '@smithsonian/js/photocontest';

$(document).ready(function () {
    $('#mobile-icon').on('click', function () {
        $(this).toggleClass('open');
        $('#sideNav').toggleClass('open');
    });
});

// Slideshow
$(document).ready(function () {
    var imageCount = $('.slideshow .slide').length;
    var slideCount = 1;

    $('.slideshow .fa-chevron-right, .slideshow .fa-chevron-square-right').on(
        'click',
        function () {
            $('.slideshow .slide').hide();

            if (slideCount < imageCount) {
                $('.slideshow .slide').eq(slideCount).show();
                slideCount++;
            } else {
                $('.slideshow .slide').eq(0).show();
                slideCount = 1;
            }
        }
    );

    $('.slideshow .fa-chevron-left, .slideshow .fa-chevron-square-left').on(
        'click',
        function () {
            $('.slideshow .slide').hide();

            if (slideCount != 1) {
                $('.slideshow .slide')
                    .eq(slideCount - 2)
                    .show();
                slideCount--;
            } else {
                $('.slideshow .slide')
                    .eq(imageCount - 1)
                    .show();
                slideCount = imageCount;
            }
        }
    );

    // Current slide number
    $('.slide-number').each(function () {
        $(this)
            .children('.current-slide')
            .text($(this).parent().parent().parent().index() + 1);
        $(this).children('.total-slides').text($('.slide-number').length);
    });
});

// Category page infinite scroll
$(document).ready(function () {
    var currentPage = 1;
    var nextPage = 2;
    var url = window.location.href;

    if ($('#loadMore').length) {
        var debounce = null;
        $(window).on('scroll', function (e) {
            clearTimeout(debounce);
            debounce = setTimeout(function () {
                var hT = $('#loadMore').offset().top,
                    hH = $('#loadMore').outerHeight() - 10000,
                    wH = $(window).height(),
                    wS = $(this).scrollTop(),
                    nextURL;

                if (window.location.href.indexOf('?') > -1) {
                    nextURL =
                        window.location.href.split('&page')[0] +
                        '&page=' +
                        nextPage;
                } else {
                    nextURL =
                        window.location.href.split('?')[0] +
                        '?page=' +
                        nextPage;
                }

                if (wS > hT - wH) {
                    $.ajax({
                        type: 'GET',
                        url: nextURL,
                        dataType: 'HTML',
                        success: function (response) {
                            setTimeout(function () {
                                $(
                                    '<div id="articleContainer' +
                                        nextPage +
                                        '" class="article-container"></div>'
                                ).appendTo('#articleList');
                                $('#articleContainer' + nextPage).load(
                                    nextURL + ' #articleContainer > *'
                                );
                                nextPage++;
                            }, 100);
                        },
                        complete: function () {},
                        error: function (response) {
                            console.log('error loading articles');
                            $('#loadMore').hide();
                        },
                    });
                }
            }, 100);
        });
    }
});

// Article detail page load Disqus on button click
$(document).ready(function () {
    $('.article-comments #trigger').on('click', function () {
        $(
            "<script type='text/javascript'>var disqus_shortname = 'shortname',disqus_identifier = 'identifier';(function() {var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);})();</script>"
        ).appendTo('#disqus_thread');
        $(this).hide();
    });
});
